import React, { useEffect, useRef, useState, useMemo, memo } from "react";
import {
  deleteUser,
  getUserCount,
  roleLevel,
  userWithComment,
  getUsersList,
} from "../../services/user";
import Lists from "../../modules/hcmd/components/Lists";
import { Link, useParams } from "react-router-dom";
import { usePermission, useRoles } from "../../services/auth";
import PropTypes from "prop-types";
import { darken, lighten } from "@mui/material/styles";
import { grey, red } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Switch,
} from "@mui/material";
import User from "./User";
import { userCreatePermission } from "../../services/auth";
import { getEmployees, getOffices } from "../../services/address-book";
import Filters from "./filters/Filters";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Apply from "./filters/Apply";
import Loading from "../../modules/hcmd/components/Loading";
import { useDictionary } from "../../services/dictionary";
import Box from "@mui/material/Box";
import { saveAs } from 'file-saver';
import moment from "moment";
// import { writeFileXLSX } from "xlsx";
// import XLSX from 'xlsx';

Users.propTypes = {
  type: PropTypes.oneOf(["md", "hc"]).isRequired,
  id: PropTypes.number,
};

function Users(props) {
  let { type, id } = props;

  let typeNumber, site;
  if (type === "md") {
    typeNumber = 10;
    site = "md";
  } else if (type === "hc") {
    typeNumber = 20;
    site = "hc";
  }

  const emptyOptions = {
    offices: [],
    countries: [],
    fieldsOfWorkCategories: [],
    fieldsOfWorks: [],
    workTypeMds: [],
    languages: [],
    qualifications: [],
    fieldOfStudyCategories: [],
    fieldOfStudies: [],
    computerSkillCategories: [],
    computerSkills: [],
    drivingLicences: [],
    tags: [],
    spareTimes: [],
    employees: [],
    counties: [],
    referers: [],
    cities: [],
  };

  const refCheckboxes = useRef([]);
  const refChecked = useRef([]);
  const refApply = useRef(null);

  const passFiltered = () => {
    if (refApply.current && refChecked.current) {
      refApply.current.passFiltered(refChecked.current);
    }
  }

  const [loading, setLoading] = useState(true);
  const [addonHeader, setAddonHeader] = useState(<></>);
  const [options, setOptions] = useState(null);
  const [defaultListFilter, setDefaultListFilter] = useState({
    type: typeNumber,
    isActive: true,
    status: { inq: [25, 30] },
  });
  const [listFilter, setListFilter] = useState(defaultListFilter);
  // const [filtered, setFiltered] = useState(refCheckboxes.current);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [columns, setColumns] = useState([]);
  const [createButtons, setCreateButtons] = useState(<></>);

  const permissions = usePermission("user");

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }
  if (params.site && ["md", "hc"].includes(params.site)) {
    site = params.site;
  }

  const roles = useRoles();

  useEffect(() => {
    if (roleLevel(roles) > 60) {
      setAddonHeader(
        <>
          <FormControlLabel
            control={
              <Switch
                onClick={(event) => {
                  if (event.target.checked) {
                    setDefaultListFilter({
                      type: typeNumber,
                    });
                  } else {
                    setDefaultListFilter({
                      type: typeNumber,
                      isActive: true,
                      status: { inq: [25, 30] },
                    });
                  }
                }}
              />
            }
            label="Inaktív felhasználók is"
          />
        </>
      );
    }
    return () => {};
  }, [roles]);

  const dictionaryOptions = Object.assign({}, emptyOptions);
  dictionaryOptions.offices = useDictionary("offices");
  dictionaryOptions.countries = useDictionary("countries", [
    {
      order: ["priority ASC"],
      fields: ["id", "name", "nationality"],
    },
  ]);
  dictionaryOptions.fieldsOfWorkCategories = useDictionary(
    "fieldsOfWorkCategories"
  );
  dictionaryOptions.fieldsOfWorks = useDictionary("fieldsOfWorks");
  dictionaryOptions.workTypeMds = useDictionary("workTypeMds");
  dictionaryOptions.languages = useDictionary("languages");
  dictionaryOptions.qualifications = useDictionary("qualifications");
  dictionaryOptions.fieldOfStudyCategories = useDictionary(
    "fieldOfStudyCategories"
  );
  dictionaryOptions.fieldOfStudies = useDictionary("fieldOfStudies");
  dictionaryOptions.computerSkillCategories = useDictionary(
    "computerSkillCategories"
  );
  dictionaryOptions.computerSkills = useDictionary("computerSkills");
  dictionaryOptions.drivingLicences = useDictionary("drivingLicences");
  dictionaryOptions.tags = useDictionary("tags");
  dictionaryOptions.spareTimes = useDictionary("spareTimes");
  dictionaryOptions.employees = useDictionary("employees");
  dictionaryOptions.cities = useDictionary("cities");
  dictionaryOptions.counties = useDictionary("counties");
  dictionaryOptions.referers = useDictionary("referers");

  useEffect(() => {
    let isAllLoaded = true;
    Object.keys(dictionaryOptions).map((attr) => {
      if (!dictionaryOptions[attr] || !dictionaryOptions[attr].length) {
        isAllLoaded = false;
      }
    });
    if (loading && isAllLoaded) {
      setOptions(dictionaryOptions);
      setLoading(false);
    }
  }, [dictionaryOptions]);

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  useEffect(() => {
    if (
      !Object.keys(defaultListFilter).includes("isActve") &&
      !Object.keys(defaultListFilter).includes("status")
    ) {
      const newListFilter = JSON.parse(JSON.stringify(listFilter));
      delete newListFilter.isActive;
      delete newListFilter.status;
      setListFilter(newListFilter);
    } else {
      setListFilter({ ...defaultListFilter, ...listFilter });
    }
  }, [defaultListFilter]);

  useEffect(() => {
    if (permissions.includes("create")) {
      let createBtn;
      if (site === "md") {
        createBtn = (
          <Link to={`0`}>
            <Button variant={"contained"} color={"error"}>
              Diák munkavállaló létrehozása
            </Button>
          </Link>
        );
      } else if (site === "hc") {
        createBtn = (
          <Link to={`0`}>
            <Button variant={"contained"} color={"error"}>
              Felnőtt munkavállaló létrehozása
            </Button>
          </Link>
        );
      }
      setCreateButtons(
        <>
          <Grid container spacing={2}>
            <Grid>{createBtn}</Grid>
          </Grid>
        </>
      );
    }
  }, [permissions, site]);

  // ide jon az oszlopok deklaralasa
  useEffect(() => {
    if (options) {
      setColumns([
        {
          field: "id",
          fieldType: "number",
          hide: !isFiltered,
          width: 80,
          renderCell: (params) => {
            if (isFiltered) {
              return  (
                  <input type={'checkbox'}
                         ref={(el) => (refCheckboxes.current[params.row.id] = el)}
                         checked={refChecked.current.includes(params.row.id)}
                         onChange={() => {
                           if (refChecked.current.includes(params.row.id)) {
                             refChecked.current = refChecked.current.filter((item) => item !== params.row.id);
                             setTimeout(() => {
                               refCheckboxes.current[params.row.id].checked = false;
                             }, 50);
                           } else {
                             refChecked.current = [...refChecked.current, params.row.id];
                             setTimeout(() => {
                               refCheckboxes.current[params.row.id].checked = true;
                             }, 50);
                           }
                           passFiltered();
                         }}
                         value={params.row.id}
                  />
              );
              // return (
              //   <Checkbox
              //     ref={(el) => (refCheckboxes.current[params.row.id] = el)}
              //     checked={filtered.includes(params.row.id)}
              //     onChange={() => {
              //       if (filtered.includes(params.row.id)) {
              //         setFiltered(
              //           filtered.filter((item) => item !== params.row.id)
              //         );
              //       } else {
              //         setFiltered([...filtered, params.row.id]);
              //       }
              //     }}
              //     value={params.row.id}
              //   />
              // );
            }
            return params.row.id;
          },
        },
        {
          field: "name",
          fieldType: "text",
          headerName: "Név",
          // valueGetter: (params) => {
          //   console.log('name', params.row, userWithComment(params.row));
          //   // return `${params.row.lastName} ${params.row.firstName}`;
          //   return `${params.row.lastName} ${params.row.firstName}`;
          // },
          renderCell: (params) => {
            return userWithComment(params.row);
          },
        },
        {
          field: "cityId",
          fieldType: "text",
          headerName: "Település",
          sortable: false,
          valueGetter: (params) => {
            const cityId =
              params.row.userMd?.cityId ?? params.row.userHc?.cityId ?? 0;
            let cityName = "";
            if (cityId) {
              const city = dictionaryOptions.cities.find(
                (item) => item.id === cityId
              );
              if (city) {
                cityName = city.name;
              }
            }
            return cityName;
          },
        },
        {
          field: "officeId",
          fieldType: "text",
          headerName: "Iroda",
          sortable: false,
          valueGetter: (params) => {
            const officeId =
              params.row.userMd?.officeId ?? params.row.userHc?.officeId ?? 0;
            let officeName = "";
            if (officeId) {
              const office = dictionaryOptions.offices.find(
                (item) => item.id === officeId
              );
              if (office) {
                officeName = office.name;
              }
            }
            return officeName;
          },
        },
        {
          field: "email",
          fieldType: "text",
          headerName: "E-mail",
        },
        {
          field: "status",
          fieldType: "number",
          defaultValue: 10,
          headerName: "Státusz",
          width: 100,
          valueGetter: (params) => {
            if (!params.row.isActive) {
              return "inaktív";
            } else {
              switch (params.row.status) {
                case 10:
                  return "függőben";
                  break;
                case 20:
                  return "validált";
                  break;
                case 25:
                  return "egyszerűsített";
                  break;
                case 30:
                  return "aktív";
                  break;
                case 40:
                  return "törölt";
                  break;
              }
            }
            return "ismeretlen";
          },
        },
        {
          field: "actions",
          headerName: "Műveletek",
          width: 120,
          align: "right",
          headerAlign: "right",
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          headerClassName: "text-dark",
          cellClassName: "text-dark",
          renderCell: (params) => {
            return (
              <>
                <Link to={`${header.url}/${params.row.id}`}>
                  <IconButton
                    variant={"contained"}
                    aria-label="szerkesztés"
                    size="large"
                    className={"me-3"}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </>
            );
          },
        },
      ]);
    }
  }, [site, options, isFiltered]);

  // ide jon a fejlec deklaralasa
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
  const getSelectedBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
  const header = {
    title: `${type === "hc" ? "Felnőtt" : ""}${
      type === "md" ? "Diák" : ""
    } munkavállalók`,
    url: `/users/${type}`,
    item: "munkavállaló",
    disableColumnsToolbar: true,
    disableFilterToolbar: true,
    disableDensityToolbar: true,
    disableExportToolbar: true,
    styles: ({ theme }) => ({
      "& .row-inactive": {
        backgroundColor: getBackgroundColor(grey[300], theme.palette.mode),
        color: grey[500],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            grey[300],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              grey[300],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-pending": {
        backgroundColor: getBackgroundColor(grey[200], theme.palette.mode),
        color: grey[500],
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            grey[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            grey[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              grey[200],
              theme.palette.mode
            ),
          },
        },
      },
      "& .row-deleted": {
        backgroundColor: getBackgroundColor(red[200], theme.palette.mode),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(
            red[200],
            theme.palette.mode
          ),
        },
        "&.Mui-selected": {
          backgroundColor: getSelectedBackgroundColor(
            red[200],
            theme.palette.mode
          ),
          "&:hover": {
            backgroundColor: getSelectedHoverBackgroundColor(
              red[200],
              theme.palette.mode
            ),
          },
        },
      },
    }),
    getRowClassName: (params) => {
      if (!params.row.isActive || params.row.status <= 20) {
        return "row-inactive";
      } else if (params.row.status === 10) {
        return "row-pending";
      } else if (params.row.status === 40) {
        return "row-deleted";
      } else {
        return "row-active";
      }
    },
  };

  const addFilters = (where) => {
    if (where && Object.keys(where).length) {
      setIsFiltered(true);
    }
    setListFilter({ ...defaultListFilter, ...where });
    refCheckboxes.current.map((item, id) => {
      setTimeout(() => {
        if (item) {
          try {
            item.checked = false;
          } catch (err) {
            // not visible?
          }
        }
      }, 50);
    });
    refChecked.current = [];
    passFiltered();
    // setFiltered([]);
  };

  const handleSelectAll = () => {
    if (refCheckboxes.current.length) {
      const ids = [];
      refCheckboxes.current.map((item, id) => {
        // console.log("ref", item, id);
        if (item) {
          ids.push(id);
        }
        setTimeout(() => {
          if (item) {
            try {
              item.checked = true;
            } catch (err) {
              // not visible?
            }
          }
        }, 50);
      });
      refChecked.current = ids;
      passFiltered();
      // setFiltered(ids);
    }
  };

  const exportToExcel = (data, fileName) => {
    const XLSX = require("xlsx");
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
    saveAs(blob, `${fileName}.xlsx`);
  };

  const handleExportToExcel = () => {
    // console.log('handleExportToExcel', listFilter);
    getUsersList(listFilter, [], 100000, 0, ['userQualifications']).then(response => {
      // console.log('toExport', response);
      const toExport = response.data.map(item => {
        const userSite = item.userMd ?? item.userHc;
        const cityId =
            userSite?.cityId ?? 0;
        let cityName = "";
        if (cityId) {
          const city = dictionaryOptions.cities.find(
              (item) => item.id === cityId
          );
          if (city) {
            cityName = city.name;
          }
        }
        const officeId =
            userSite?.officeId ?? 0;
        let officeName = "";
        if (officeId) {
          const office = dictionaryOptions.offices.find(
              (item) => item.id === officeId
          );
          if (office) {
            officeName = office.name;
          }
        }
        let status = 'ismeretlen';
        if (!item.isActive) {
          status = "inaktív";
        } else {
          switch (item.status) {
            case 10:
              status = "függőben";
              break;
            case 20:
              status = "validált";
              break;
            case 25:
              status = "egyszerűsített";
              break;
            case 30:
              status = "aktív";
              break;
            case 40:
              status = "törölt";
              break;
          }
        }
        const qualifications = (item.userQualifications ?? []).map(row => dictionaryOptions.qualifications.find(qualification => qualification.id === row.qualificationId).name ?? '').join(', ');
        return {
          id: item.id,
          "név": `${item.lastName} ${item.firstName}`,
          "település": cityName,
          "születési dátum": item.birthDate ? moment(item.birthDate).format('YYYY-MM-DD') : '',
          "végzettség": qualifications,
          "mobilszám": userSite?.phone_1Number ? `${userSite?.phone_1Country ?? '36'} ${userSite?.phone_1Region ?? ''} ${userSite?.phone_1Number ?? ''}` : '',
          // iroda: officeName,
          email: item.email,
          "státusz": status,
          "link": `https://ats.humancentrum.hu/users/${item.type === 10 ? 'md' : 'hc'}/${item.id}`,
        };
      });
      exportToExcel(toExport, 'felhasznalok');
    });
  }

  return (
    <>
      {loading && <Loading></Loading>}
      {!loading && columns.length > 0 && options !== null && (
        <>
          {isForm && <User id={id} site={site} options={options}></User>}
          {!isForm && (
            <>
              {Object.keys(options).length > 0 && (
                <Filters
                  site={site}
                  addFilters={addFilters}
                  options={options}
                ></Filters>
              )}
              {isFiltered === true && (
                  <>
                    <Grid container justifyContent={'space-between'}>
                      <Apply
                          ref={refApply}
                          selectAll={handleSelectAll}
                          // passedFiltered={filtered}
                          site={site}
                      ></Apply>
                      <Box>
                        <Button variant={'contained'} onClick={handleExportToExcel}>Excel</Button>
                      </Box>
                    </Grid>
                  </>
              )}
              <Lists
                header={header}
                addonHeader={addonHeader}
                permissions={permissions}
                getFunc={getUsersList}
                getCountFunc={getUserCount}
                getParams={listFilter}
                deleteFunc={deleteUser}
                columns={columns}
                createButtons={createButtons}
                dataGrid={{ disableColumnFilter: true }}
              ></Lists>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Users;
