import axios from "axios";
import { isactiveFilter } from "./dictionary";
const apiUrl = process.env.REACT_APP_API_URL;

export const getNewsletters = (where, order = ['id DESC'], limit = 25, skip = 0) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/newsletters`, { params: filter });
};
export const getNewsletterCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/newsletters/count`, { params: filter });
};
export const getNewsletter = (id) => {
  return axios.get(`${apiUrl}/newsletters/${id}`, {});
};
export const setNewsletter = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/newsletters/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/newsletters`, data);
  }
};
export const deleteNewsletter = (id) => {
  return axios.delete(`${apiUrl}/newsletters/${id}`, {});
};

export const getEmailSubscribers = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/email-subscribers`, { params: filter });
};
export const getEmailSubscriberCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/email-subscribers/count`, { params: filter });
};
export const getEmailSubscriber = (id) => {
  return axios.get(`${apiUrl}/email-subscribers/${id}`, {});
};
export const setEmailSubscriber = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/email-subscribers/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/email-subscribers`, data);
  }
};
export const deleteEmailSubscriber = (id) => {
  return axios.delete(`${apiUrl}/email-subscribers/${id}`, {});
};

export const getServices = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0,
  fields = [],
  withInactives = false
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  if (fields.length) {
    filter.fields = fields;
  }
  return axios.get(`${apiUrl}/services`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};

export const getOffices = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0,
  fields = [],
  withInactives = false
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  if (fields.length) {
    filter.fields = fields;
  }
  return axios.get(`${apiUrl}/admin/offices`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getOfficeCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/offices/count`, { params: filter });
};
export const getOffice = (id) => {
  return axios.get(`${apiUrl}/offices/${id}`, {});
};
export const setOffice = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/offices/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/offices`, data);
  }
};
export const deleteOffice = (id) => {
  return axios.delete(`${apiUrl}/offices/${id}`, {});
};

export const getCompanies = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0,
  fields = [],
  withInactives = false
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  if (fields.length) {
    filter.fields = fields;
  }
  return axios.get(`${apiUrl}/companies`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};

export const getAdminCompanies = (
    where,
    order = ['id DESC'],
    limit = 25,
    skip = 0,
    fields = [],
    withInactives = false
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  if (fields.length) {
    filter.fields = fields;
  }
  return axios.get(`${apiUrl}/admin/companies`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getCompanyCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/companies/count`, { params: filter });
};
export const getCompany = (id) => {
  return axios.get(`${apiUrl}/admin/companies-by-id/${id}`, {});
};
export const setCompany = (id, data) => {
  delete data.fullAddress;
  if (!data.countyId) {
    delete data.countyId;
  }
  if (!data.countryId) {
    delete data.countryId;
  }
  if (id) {
    return axios.patch(`${apiUrl}/companies/${id}`, data);
  } else {
    delete data.id;
    return axios.post(`${apiUrl}/companies`, data);
  }
};
export const deleteCompany = (id) => {
  return axios.delete(`${apiUrl}/companies/${id}`, {});
};

export const getCompanyRequests = (where, order = ['id DESC'], limit = 25, skip = 0) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/company-requests`, { params: filter });
};
export const getCompanyRequestCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/company-requests/count`, { params: filter });
};
export const getCompanyRequest = (id) => {
  return axios.get(`${apiUrl}/company-requests/${id}`, {});
};
export const setCompanyRequest = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/company-requests/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/company-requests`, data);
  }
};
export const deleteCompanyRequest = (id) => {
  return axios.delete(`${apiUrl}/company-requests/${id}`, {});
};

export const getOfficeEmployee = (id) => {
  return axios.get(`${apiUrl}/admin/office-employees/${id}`, {});
};

export const getOfficeSales = (id) => {
  return axios.get(`${apiUrl}/admin/office-employees/${id}/hca_ev,hca_e`, {});
};

export const getOfficeProjectManagers = (id) => {
  return axios.get(`${apiUrl}/admin/office-employees/${id}/hca_p`, {});
};

export const getPositionOwners = (id) => {
  return axios.get(`${apiUrl}/admin/employees/role/hca_ev`, {});
};

export const getEmployees = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0,
  fields = [],
  withInactives = false
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  if (fields.length) {
    filter.fields = fields;
  }
  return axios.get(`${apiUrl}/admin/employees`, {
    params: withInactives ? filter : isactiveFilter(filter),
  });
};
export const getEmployeeCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/employees/count`, { params: filter });
};
export const getEmployee = (id) => {
  return axios.get(`${apiUrl}/admin/employees/${id}`, {});
};
export const setEmployee = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/admin/employees/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/admin/employees`, data);
  }
};
export const deleteEmployee = (id) => {
  return axios.delete(`${apiUrl}/admin/employees/${id}`, {});
};
