import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, Switch } from "@mui/material";
import { formError, formErrorMsg, formSetValue } from "./formUtils";

const InputForwardRef = (props, ref) => {
  const { formik, initialValue, column, dirtying } = props;

  // let isChecked =
  //   column.defaultChecked !== undefined ? column.defaultChecked : false;
  // if (Object.keys(formik.values).includes(column.field)) {
  //   isChecked =
  //       initialValue ?? false;
  // }

  const [checked, setChecked] = useState(false);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const initValue = initialValue;
    if (!inited && initValue !== undefined && initValue !== null) {
      setChecked(initValue);
    }
    setInited(true);
  }, [initialValue]);

  // useEffect(() => {
  // if (checked !== isChecked) {
  //   setChecked(isChecked);
  // formSetValue(formik, column, isChecked);
  // }
  // }, [formik, column]);

  useEffect(() => {
    formSetValue(formik, column, checked);
    const force = (checked !== initialValue);
    dirtying(force);
    formError(formik, column);
  }, [checked]);

  return (
    <div className="row">
      <label
        className={`col-lg-4 col-form-label fw-bold fs-6 ${
          column.validation?.required && "required"
        }`}
      >
        {column.headerName}
      </label>
      <div className="col-lg-8 d-flex">
        <FormControl>
          <Switch
            id={column.field}
            onChange={(event) => {
              setChecked(event.target.checked);
            }}
            checked={checked}
          />
          <input
            type="hidden"
            name={column.field}
            value={checked === true ? "true" : "false"}
          />
          {formErrorMsg(formik, column)}
          {column.helperText && (
            <FormHelperText sx={{ mx: 0 }}>{column.helperText}</FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};

const BooleanInput = forwardRef(InputForwardRef);

BooleanInput.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  column: PropTypes.object.isRequired,
  dirtying: PropTypes.func.isRequired,
};

export default BooleanInput;
