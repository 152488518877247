import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Quill from "quill";
import { ImageHandler, VideoHandler, AttachmentHandler } from "quill-upload";
import { quillUpload } from "../../../services/auth";
import "quill-paste-smart";
import parse from "html-react-parser";

const InputForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => ({
    returnQuill() {
      return quill;
    },
  }));

  const { column, initialValue, value, onChange } = props;
  const [quill, setQuill] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const refTimer = useRef(null);

  const refQuill = useRef(null);

  const htmlToDelta = (html) => {
    const div = document.createElement('div');
    div.setAttribute('id', 'htmlToDelta');
    div.innerHTML = `<div id="quillEditor" style="display:none">${html}</div>`;
    document.body.appendChild(div);
    const quill = new Quill('#quillEditor', {
      theme: 'snow',
    });
    const delta = quill.getContents();
    document.getElementById('htmlToDelta').remove();
    return delta;
  }

  // useEffect(() => {
    // const delta = htmlToDelta(value);
    // console.log('delta', delta);
  // }, [value]);

  useEffect(() => {
    // register quill-upload
    Quill.register("modules/imageHandler", ImageHandler);
    // Quill.register("modules/videoHandler", VideoHandler);
    // Quill.register("modules/attachmentHandler", AttachmentHandler);

    setQuill(
      new Quill(`#editor-${column.field}`, {
        theme: "snow",
        // debug: "info",
        modules: {
          clipboard: {
            allowed: {
              tags: [
                "a",
                "b",
                "strong",
                "u",
                "s",
                "i",
                "p",
                "br",
                "ul",
                "ol",
                "li",
                "span",
              ],
              attributes: ["href", "rel", "target", "class"],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {
              uponSanitizeElement(node, data, config) {
                console.log(node);
              },
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            // [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            // [{ font: [] }],
            // [{ align: [] }],

            ["clean"], // remove formatting button

            ["image"],
          ],
          imageHandler: {
            upload: (file) => {
              // return a Promise that resolves in a link to the uploaded image
              return new Promise((resolve, reject) => {
                const fd = new FormData();
                fd.append("file", file);
                fd.append("fileName", file.name);
                fd.append("imageUrl", file.name);

                // ajax().then(data => resolve(data.imageUrl));
                quillUpload(column.id ?? 0, fd)
                  .then((response) => {
                    console.log(
                      "quillUpload",
                      response,
                      response.data.fields.imageUrl
                    );
                    if (response.data?.fields?.imageUrl) {
                      resolve(response.data.fields.imageUrl);
                    } else {
                      reject(response.data.errors);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    reject(err);
                  });
              });
            },
          },
          // videoHandler: {
          //   upload: file => {
          //     // return a Promise that resolves in a link to the uploaded image
          //     return new Promise((resolve, reject) => {
          //       ajax().then(data => resolve(data.videoUrl));
          //     });
          //   }
          // },
          // attachmentHandler: {
          //   upload: file => {
          //     // return a Promise that resolves in a link to the uploaded image
          //     return new Promise((resolve, reject) => {
          //       ajax().then(data => resolve(data.attachmentUrl));
          //     });
          //   }
          // },
        },
      })
    );
    return () => {
      setQuill(null);
    };
  }, []);

  useEffect(() => {
    if (quill) {
      // window.WEBSPELLCHECKER.init({
      //   container: quill.root,
      // });

      quill.on("text-change", function (delta, oldDelta, source) {
        onChange(quill);
      });
      setInterval(() => {
        if (quill.hasFocus()) {
          if (refTimer.current) clearTimeout(refTimer.current);
          setIsFocused(true);
        } else {
          refTimer.current = setTimeout(() => {
            setIsFocused(false);
          }, 100);
        }
      }, 100);
    }
  }, [quill]);

  return (
    <div className={isFocused ? 'on-focus' : 'non-focus'}>
      <div id={`editor-${column.field}`} ref={refQuill}>
        {parse(initialValue ?? '')}
      </div>
    </div>
  );
};

const QuillEditor = forwardRef(InputForwardRef);

QuillEditor.propTypes = {};

export default QuillEditor;
