import React, {useEffect, useRef, useState} from "react";
import {
  getNewsletters,
  getNewsletter,
  setNewsletter,
  deleteNewsletter,
  getNewsletterCount,
} from "../../services/address-book";
import Lists from "../../modules/hcmd/components/Lists";
import { useParams } from "react-router-dom";
import Forms from "../../modules/hcmd/components/Forms";
import { usePermission } from "../../services/auth";

NewsletterMds.propTypes = {};

function NewsletterMds(props) {
  let { id } = props;

  const [isForm, setIsForm] = useState(false);
  const [filter, setFilter] = useState('');
  const [listFilter, setListFilter] = useState({ type: 20 });
  const permissions = usePermission("dictionary");

  const params = useParams();
  if (!id && !isNaN(parseInt(params.id)) && parseInt(params.id) >= 0) {
    id = parseInt(params.id);
    if (!isForm) {
      setIsForm(true);
    }
  } else if (!isNaN(parseInt(id)) && parseInt(id) >= 0 && !isForm) {
    setIsForm(true);
  } else if (isForm) {
    setIsForm(false);
  }

  // ide jonnek a tipusnal hasznalt egyeb adatok deklaralasa
  const frequencies = [
    {
      id: 10,
      value: "heti",
      name: "heti",
    },
    {
      id: 20,
      value: "napi",
      name: "napi",
    },
  ];

  // ide jon az oszlopok deklaralasa
  const columns = [
    {
      field: "id",
      fieldType: "number",
      hide: true,
    },
    {
      field: "name",
      fieldType: "text",
      headerName: "Név",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "email",
      fieldType: "text",
      headerName: "E-mail",
      validation: {
        required: true,
        minLength: 3,
      },
    },
    {
      field: "frequency",
      fieldType: "select",
      headerName: "Gyakoriság",
      valueGetter: (params) => {
        let v = "egyéb";
        switch (params.row.frequency) {
          case 10:
            v = "heti";
            break;
          case 20:
            v = "napi";
            break;
        }
        return v;
      },
    },
    {
      field: "isActive",
      fieldType: "boolean",
      defaultValue: true,
      headerName: "Státusz",
      width: 100,
      valueGetter: (params) => {
        return params.row.isActive ? "aktív" : "inaktív";
      },
      submitTransform: (value) => {
        return value ? 1 : 0;
      },
    },
    {
      field: "userId",
      fieldType: "",
      headerName: "Felhasználó ID",
      width: 100,
    },
  ];

  // ide jon a fejlec deklaralasa
  const header = {
    title: "Állásposta feliratkozottak (MD)",
    url: "/address-books/newsletter-mds",
    item: "állásposta feliratkozott",
  };

  const refTimeout = useRef(null);

  const handleQuickfilter = (value) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
      refTimeout.current = null;
    }
    refTimeout.current = setTimeout(() => {
      setFilter(value);
    }, 100);
  }

  useEffect(() => {
    const where = {};
    if (filter.toString() !== '') {
      const like = `%${filter}%`;
      where.and = [
        { type: 20 },
        {or: [
            {name: {like: like}},
            {email: {like: like}},
          ]},
      ];
    }
    setListFilter(where);
  }, [filter]);

  return (
    <>
      {isForm && (
        <Forms
          editable={true}
          id={id}
          header={header}
          permissions={permissions}
          columns={columns}
          getFunc={getNewsletter}
          getParams={{ type: 20 }}
          setFunc={setNewsletter}
          frequencies={frequencies}
        ></Forms>
      )}
      {!isForm && (
        <Lists
          header={header}
          permissions={permissions}
          getFunc={getNewsletters}
          getCountFunc={getNewsletterCount}
          quickfilterFunc={handleQuickfilter}
          getParams={listFilter}
          deleteFunc={deleteNewsletter}
          columns={columns}
          frequencies={frequencies}
        ></Lists>
      )}
    </>
  );
}

export default NewsletterMds;
