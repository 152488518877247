import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";
import { FormLabel, Typography } from "@mui/material";
import { getAutocompleteCities } from "../../../services/position";
import { getCounties, getCountries } from "../../../services/dictionary";

const PlaceForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [site, setSite] = useState("");
  const [siteModel, setSiteModel] = useState("");
  const [columns, setColumns] = useState([]);

  const [zip, setZip] = useState("");
  const [cityName, setCityName] = useState("");
  const [zipPermanent, setZipPermanent] = useState("");
  const [cityNamePermanent, setCityNamePermanent] = useState("");

  useEffect(() => {}, []);

  useEffect(() => {
    if (user.type === 10) {
      setSite("md");
      setSiteModel("userMd");
    } else if (user.type === 20) {
      setSite("hc");
      setSiteModel("userHc");
    }
    return () => {
      setSite("");
      setSiteModel("");
    };
  }, [user]);

  useEffect(() => {
    if (siteModel) {
      const sectionColumns = [
        [
          [
            {
              field: "phone_1Country",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
          [
            {
              field: "phone_1Region",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
          [
            {
              field: "phone_1Number",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
        ],
        [
          [
            {
              field: "phone_2Country",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
          [
            {
              field: "phone_2Region",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
          [
            {
              field: "phone_2Number",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "",
            },
          ],
        ],
        // tartozkodasi hely
        [
          [
            {
              field: "countryId",
              fieldType: "select",
              fieldModel: siteModel,
              headerName: "Ország",
              defaultValue: 61,
              options: options.countries,
            },
          ],
          [
            {
              field: "zip",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Irányítószám",
              onChange: (value) => {
                setZip(value);
              },
            },
          ],
          [
            {
              field: "address",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Utca, házszám",
            },
          ],
        ],
        // allando lakcim
        [
          [
            {
              field: "permanentCountryId",
              fieldType: "select",
              fieldModel: siteModel,
              headerName: "Ország",
              defaultValue: 61,
              options: options.countries,
            },
          ],
          [
            {
              field: "permanentZip",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Irányítószám",
              onChange: (value) => {
                setZipPermanent(value);
              },
            },
          ],
          [
            {
              field: "permanentAddress",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Utca, házszám",
            },
          ],
        ],

        // social
        [
          [
            {
              field: "website",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Honlap/blog URL",
            },
            {
              field: "facebook",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Facebook",
            },
            {
              field: "twitter",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Twitter",
            },
            {
              field: "linkedin",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "LinkedIn",
            },
          ],
          [
            {
              field: "youtube",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Youtube",
            },
            {
              field: "googleplus",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Google+",
            },
            {
              field: "tiktok",
              fieldType: "text",
              fieldModel: siteModel,
              headerName: "Tiktok",
            },
          ],
        ],
      ];
      setColumns(sectionColumns);
    }

    return () => {};
  }, [site, siteModel, options]);

  useEffect(() => {
    setZip(user.userMd?.zip ?? user.userHc?.zip ?? "");
    setZipPermanent(
      user.userMd?.permanentZip ?? user.userHc?.permanentZip ?? ""
    );
  }, [user]);

  useEffect(() => {
    if (zip && options.counties.length) {
      getAutocompleteCities(zip)
        .then((response) => {
          let city = response.data?.[0]?.name;
          const found = options.counties.find(
            (item) => item.id === response.data?.[0]?.countyId ?? 0
          );
          if (found) {
            city += ` / ${found.name}`;
          }
          setCityName(city);
        })
        .catch((err) => console.error(err));
    } else {
      setCityName("");
    }
  }, [zip, options]);

  useEffect(() => {
    if (zipPermanent && options.counties.length) {
      getAutocompleteCities(zipPermanent)
        .then((response) => {
          let city = response.data?.[0]?.name;
          const found = options.counties.find(
            (item) => item.id === response.data?.[0]?.countyId ?? 0
          );
          if (found) {
            city += ` / ${found.name}`;
          }
          setCityNamePermanent(city);
        })
        .catch((err) => console.error(err));
    } else {
      setCityNamePermanent("");
    }
  }, [zipPermanent, options]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <>
      {columns.length > 0 && (
        <Grid container spacing={0}>
          <Grid md={5} container alignItems={"center"}>
            <Grid md={12}>
              <FormLabel className={"col-form-label fw-bold fs-6"}>
                Mobilszám
              </FormLabel>
            </Grid>
            <Grid md={2}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[0][0]}
                getObj={user}
                ref={(el) => (formRefs.current[0] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={1}>
              <Typography className={"text-center"}>{" - "}</Typography>
            </Grid>
            <Grid md={2}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[0][1]}
                getObj={user}
                ref={(el) => (formRefs.current[1] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={1}>
              <Typography className={"text-center"}>{" - "}</Typography>
            </Grid>
            <Grid md={6}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[0][2]}
                getObj={user}
                ref={(el) => (formRefs.current[2] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
          </Grid>
          <Grid md={2}></Grid>
          <Grid md={5} container alignItems={"center"}>
            <Grid md={12}>
              <FormLabel className={"col-form-label fw-bold fs-6"}>
                Telefonszám
              </FormLabel>
            </Grid>
            <Grid md={2}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[1][0]}
                getObj={user}
                ref={(el) => (formRefs.current[3] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={1}>
              <Typography className={"text-center"}>{" - "}</Typography>
            </Grid>
            <Grid md={2}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[1][1]}
                getObj={user}
                ref={(el) => (formRefs.current[4] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={1}>
              <Typography className={"text-center"}>{" - "}</Typography>
            </Grid>
            <Grid md={6}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[1][2]}
                getObj={user}
                ref={(el) => (formRefs.current[5] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
          </Grid>

          <Grid md={12} container>
            <Grid md={12}>
              <Typography>Tartózkodási hely</Typography>
            </Grid>
            <Grid md={4}>
              <Forms
                tint={true}
                columnStartsWith={0}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[2][1]}
                getObj={user}
                ref={(el) => (formRefs.current[6] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={4}>
              <Forms
                tint={true}
                columnStartsWith={0}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[2][0]}
                getObj={user}
                ref={(el) => (formRefs.current[7] = el)}
                submitFunc={(values) => handleSubmit(values)}
                countries={options.countries}
              ></Forms>
            </Grid>
            <Grid md={4}>
              <div className="row">
                <label className="col-lg-6 col-form-label fw-bold fs-6 ps-5">
                  Település / Megye
                </label>
                <div className="fv-row col-form-label col-lg-6">{cityName}</div>
              </div>
            </Grid>
            <Grid md={12}>
              <Forms
                tint={true}
                isDense={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[2][2]}
                getObj={user}
                ref={(el) => (formRefs.current[8] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>

            <Grid md={12}>
              <Typography>Állandó lakcím</Typography>
            </Grid>
            <Grid md={4}>
              <Forms
                isDense={true}
                tint={true}
                columnStartsWith={0}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[3][1]}
                getObj={user}
                ref={(el) => (formRefs.current[9] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
            <Grid md={4}>
              <Forms
                isDense={true}
                tint={true}
                columnStartsWith={0}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[3][0]}
                getObj={user}
                ref={(el) => (formRefs.current[10] = el)}
                submitFunc={(values) => handleSubmit(values)}
                countries={options.countries}
              ></Forms>
            </Grid>
            <Grid md={4}>
              <div className="row">
                <label className="col-lg-6 col-form-label fw-bold fs-6 ps-5">
                  Település / Megye
                </label>
                <div className="fv-row col-form-label col-lg-6">
                  {cityNamePermanent}
                </div>
              </div>
            </Grid>
            <Grid md={12}>
              <Forms
                isDense={true}
                tint={true}
                editable={isEditable}
                header={{ skip: true }}
                permissions={["create", "read", "update", "delete"]}
                columns={columns[3][2]}
                getObj={user}
                ref={(el) => (formRefs.current[11] = el)}
                submitFunc={(values) => handleSubmit(values)}
              ></Forms>
            </Grid>
          </Grid>

          <Grid md={6}>
            <Forms
              isDense={true}
              tint={true}
              columnStartsWith={0}
              editable={isEditable}
              header={{ skip: true }}
              permissions={["create", "read", "update", "delete"]}
              columns={columns[4][0]}
              getObj={user}
              ref={(el) => (formRefs.current[12] = el)}
              submitFunc={(values) => handleSubmit(values)}
            ></Forms>
          </Grid>
          <Grid md={6}>
            <Forms
              isDense={true}
              tint={true}
              columnStartsWith={0}
              editable={isEditable}
              header={{ skip: true }}
              permissions={["create", "read", "update", "delete"]}
              columns={columns[4][1]}
              getObj={user}
              ref={(el) => (formRefs.current[13] = el)}
              submitFunc={(values) => handleSubmit(values)}
            ></Forms>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const Place = forwardRef(PlaceForwardRef);
Place.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Place;
