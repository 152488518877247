import axios from "axios";
import { isactiveFilter } from "./dictionary";
const apiUrl = process.env.REACT_APP_API_URL;

export const getPositions = (where, order = ['id DESC'], limit = 25, skip = 0) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/positions`, { params: filter });
};
export const getPositionCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/positions/count`, { params: filter });
};
export const getPosition = (id) => {
  return axios.get(`${apiUrl}/admin/positions/${id}`, {});
};
export const getPositionNextStatus = (id) => {
  return axios.get(`${apiUrl}/admin/position/next-status/${id}`, {});
};
export const getMyPositions = (
  where,
  order = ['id DESC'],
  limit = 25,
  skip = 0,
  include = []
) => {
  let filter = {
    where: {},
    order: Array.isArray(order) && order.length ? order : ['id DESC'],
    limit,
    skip,
    include,
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/positions/me`, { params: filter });
};
export const getMyPositionCount = (where) => {
  let filter = {
    where: {},
  };
  if (where) {
    filter.where = where;
  }
  return axios.get(`${apiUrl}/admin/positions/me/count`, { params: filter });
};
export const setPositionNextStatus = (id) => {
  return axios.post(`${apiUrl}/admin/position/next-status/${id}`, {});
};
export const getPositionPublish = (id) => {
  return axios.get(`${apiUrl}/admin/position/publish/${id}`, {});
};
export const setPositionPublish = (id, data) => {
  return axios.post(`${apiUrl}/admin/position/publish/${id}`, data);
};
export const setPosition = (id, data) => {
  if (id) {
    return axios.patch(`${apiUrl}/admin/positions/${id}`, data);
  } else {
    return axios.post(`${apiUrl}/admin/positions`, data);
  }
};
export const deletePosition = (id) => {
  return axios.delete(`${apiUrl}/positions/${id}`, {});
};

export const applyPosition = (id, userIds) => {
  return axios.post(`${apiUrl}/admin/position/apply/${id}`, userIds);
};

export const getAutocompleteCities = (search = "") => {
  return axios.get(`${apiUrl}/admin/cities/${search}`, {});
};

export const getAutocompleteCompanies = (search = "") => {
  return axios.get(`${apiUrl}/admin/companies/${search}`, {});
};

export const getPartners = (filter = {}) => {
  return axios.get(`${apiUrl}/partners`, {
    params: isactiveFilter(filter),
  });
};

export const getPositionPartnerFeed = (positionId) => {
  return axios.get(`${apiUrl}/position-partner-feeds`, {
    params: {
      where: {
        positionId,
      },
    },
  });
};
export const setPositionPartnerFeed = (positionId, partnerId) => {
  axios.post(`${apiUrl}/position-partner-feeds/${positionId}/${partnerId}`, {});
};
export const unsetPositionPartnerFeed = (positionId, partnerId) => {
  axios.delete(
    `${apiUrl}/position-partner-feeds/${positionId}/${partnerId}`,
    {}
  );
};

export const getPositionApplicants = (positionId) => {
  return axios.get(`${apiUrl}/position-users`, {
    params: {
      order: ["updateTime DESC", "id DESC"],
      where: {
        positionId,
      },
    },
  });
};

export const setPositionUserStatus = (positionUserId, method) => {
  return axios.post(`${apiUrl}/position-users/${positionUserId}/${method}`);
};

export const getPositionEventLog = (positionId) => {
  if (positionId) {
    return axios.get(`${apiUrl}/event-logs`, {
      params: {
        where: { positionId },
        include: ["position"],
        order: ['createTime DESC'],
      },
    });
  }
  return Promise.resolve({ data: [] });
};

export const getOfficeUserEmployees = (officeId) => {
  return axios.get(`${apiUrl}/office-user-employees`, {
    params: {
      where: { officeId },
    },
  });
};

export const getUserEmployeePosition = (positionId) => {
  return axios.get(`${apiUrl}/admin/user-employee-positions`, {
    params: {
      where: { positionId },
    },
  });
};

export const addUserEmployeePosition = (positionId, userId, isAlert) => {
  return axios.post(`${apiUrl}/admin/user-employee-positions`, {
    positionId,
    userEmployeeUserId: userId,
    isAlert,
  });
};

export const getPositionComments = (positionId, isPublic=false) => {
  if (positionId) {
    const where = {
      positionId,
    };
    if (isPublic) {
      where.isPublic = true;
    }
    return axios.get(`${apiUrl}/position-comments`, {
      params: {
        where,
      },
    });
  }
  return Promise.resolve({ data: [] });
};

export const addPositionComment = (positionId, comment, isPublic) => {
  return axios.post(`${apiUrl}/admin/position-comments`, {
    positionId,
    body: comment,
    isPublic,
  });
};

export const deleteUserEmployeePosition = (id, positionId) => {
  return axios.delete(
    `${apiUrl}/admin/user-employee-positions/${positionId}/${id}`
  );
};

export const changePositionOwnerUser = (positionId, ownerUserId) => {
  return axios.post(
    `${apiUrl}/admin/position/change-owner/${positionId}/${ownerUserId}`
  );
};

export const getUserApplicantStates = () => {
  return axios.get(`${apiUrl}/user-applicant-states`, {});
};

export const getNotificationTemplateCompany = (companyId, status) => {
  let params = {};
  if (status !== undefined) {
    params = {
      ...params,
      ...{
        where: {
          status,
        },
      },
    };
  }
  return axios.get(
    `${apiUrl}/notification-templates/company/${companyId}`,
    params
  );
};

export const getNotificationTemplatePosition = (positionId, status) => {
  let params = {};
  if (status !== undefined) {
    params = {
      ...params,
      ...{
        where: {
          status,
        },
      },
    };
  }
  return axios.get(
    `${apiUrl}/notification-templates/position/${positionId}`,
    params
  );
};

export const postNotificationTemplateCompany = (companyId, data) => {
  return axios.post(
    `${apiUrl}/notification-templates/company/${companyId}`,
    data
  );
};

export const postNotificationTemplatePosition = (positionId, data) => {
  return axios.post(
    `${apiUrl}/notification-templates/position/${positionId}`,
    data
  );
};

export const eventLogTypes = [
  {
    id: 10,
    name: "Pozíció gazda változás",
  },
  {
    id: 20,
    name: "Admin hozzárendelés",
  },
  {
    id: 30,
    name: "Admin törlés",
  },
  {
    id: 40,
    name: "Jelentkezés",
  },
  {
    id: 45,
    name: "Jelölés",
  },
  {
    id: 50,
    name: "Státusz változás",
  },
  {
    id: 60,
    name: "Státusz változás",
  },
  {
    id: 70,
    name: "Paraméter változás",
  },
  {
    id: 80,
    name: "Új megjegyzés",
  },
  {
    id: 90,
    name: "Új fájl",
  },
];

export const applicationStatuses = [
  {
    id: 10,
    name: "Jelentkező",
  },
  {
    id: 20,
    name: "Potenciális jelölt",
  },
  {
    id: 25,
    name: "Továbbítva a megbízónak",
  },
  {
    id: 30,
    name: "Interjúra kiválasztott",
  },
  {
    id: 40,
    name: "Bemutatott",
  },
  {
    id: 50,
    name: "Ügyfél által kiválasztott",
  },
  {
    id: 60,
    name: "HC állományba felvett",
  },
  {
    id: 65,
    name: "MD állományba felvett",
  },
  {
    id: 70,
    name: "Ügyfél állományba felvett",
  },
  {
    id: 80,
    name: "Visszautasított",
  },
  {
    id: 90,
    name: "Parkolópályán lévő",
  },
];
