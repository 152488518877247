import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Forms from "../../../modules/hcmd/components/Forms";
import { formFunctions } from "../../../modules/hcmd/components/formUtils";
import { getLanguages } from "../../../services/dictionary";
import moment from "moment";
import {
  emailRegex,
  employmentStatuses,
  studentEmploymentStatus,
} from "../../../services/constants";
import { emailCheck } from "../../../services/user";

const BasicForwardRef = (props, ref) => {
  useImperativeHandle(ref, () => formFunctions(formRefs));
  const { user, isEditable, onSubmit, options } = props;
  const formRefs = useRef([]);

  const [site, setSite] = useState("");
  const [columns, setColumns] = useState([]);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (user.type === 10) {
      setSite("md");
    } else if (user.type === 20) {
      setSite("hc");
    }
    return () => {
      setSite("");
    };
  }, [user]);

  useEffect(() => {
    if (site && Object.keys(options).length) {
      const sectionColumns = [
        [
          {
            field: "lastName",
            fieldType: "text",
            headerName: "Vezetéknév",
            validation: {
              required: true,
            },
          },
          {
            field: "firstName",
            fieldType: "text",
            headerName: "Keresztnév",
            validation: {
              required: true,
            },
          },
          {
            field: "email",
            fieldType: !user?.id ? "text" : "",
            headerName: "E-mail cím",
            onChange: (value) => {
              if (value && value.match(emailRegex)) {
                emailCheck(site, user?.id ?? 0, { email: value })
                  .then((response) => {
                    if (!response.data) {
                      setEmailError("Ez az e-mail cím már foglalt!");
                    } else {
                      setEmailError("");
                    }
                  })
                  .catch((err) => console.error(err));
              }
            },
            validation: (value) => {
              if (emailError) {
                return emailError;
              } else if (user.id && value) {
                return false; // es a valid
              } else {
                if (!value) {
                  return "Kötelező mező!";
                } else if (!value.match(emailRegex)) {
                  return "Nem megfelelő e-mail cím formátum!";
                }
              }
            },
          },
          {
            field: "sex",
            fieldType: "select",
            headerName: "Nem",
            validation: {
              required: true,
            },
            options: [
              { id: 1, name: "férfi" },
              { id: 2, name: "nő" },
            ],
          },
        ],
      ];
      if (site === "md") {
        sectionColumns[0] = [
          ...sectionColumns[0],
          {
            field: "nativeLanguageId",
            fieldType: "select",
            fieldModel: "userMd",
            headerName: "Anyanyelv",
            validation: {
              required: true,
            },
            options: options.languages,
          },
          {
            field: "studentEmploymentStatus",
            fieldType: "select",
            fieldModel: "userMd",
            headerName: "Munkavállalói státusz",
            options: studentEmploymentStatus,
          },
          {
            field: "birthDate",
            fieldType: "date",
            headerName: "Születési dátum",
            validation: {
              required: true,
            },
            valueGetter: (params) => {
              return params.row ? moment(params.row).format("YYYY-MM-DD") : "";
            },
            submitGetter: (value) => {
              return moment(value).format("YYYY-MM-DD");
            },
          },
          {
            field: "salaryRequestHourly",
            fieldType: "number",
            fieldModel: "userMd",
            headerName: "Órabér igény (nettó)",
            validation: {
              maxLength: 9,
            },
            valueGetter: (params) => {
              return params.row ? params.row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft" : "";
            },
          },
          {
            field: "salaryRequestMonthly",
            fieldType: "number",
            fieldModel: "userMd",
            headerName: "Havibér igény (nettó)",
            validation: {
              maxLength: 9,
            },
            valueGetter: (params) => {
              return params.row ? params.row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft" : "";
            },
          },
          {
            field: "picture",
            fieldType: "upload",
            headerName: "Profilkép",
          },
        ];
      } else if (site === "hc") {
        sectionColumns[0] = [
          ...sectionColumns[0],
          {
            field: "nationalityId",
            fieldType: "select",
            fieldModel: "userHc",
            headerName: "Állampolgárság",
            validation: {
              required: true,
            },
            options: options.countries.map((item) => {
              return { id: item.id, name: item.nationality };
            }),
          },
          {
            field: "nativeLanguageId",
            fieldType: "select",
            fieldModel: "userHc",
            headerName: "Anyanyelv",
            validation: {
              required: true,
            },
            options: options.languages,
          },
          {
            field: "matrialStatus",
            fieldType: "select",
            fieldModel: "userHc",
            headerName: "Családi állapot",
            options: [
              { id: 50, name: "egyedülálló" },
              { id: 20, name: "élettársi kapcsolatban él" },
              { id: 10, name: "házas" },
              { id: 30, name: "elvált" },
              { id: 40, name: "özvegy" },
            ],
          },
          {
            field: "employmentStatus",
            fieldType: "select",
            fieldModel: "userHc",
            headerName: "Munkavállalói státusz",
            options: employmentStatuses,
          },
          {
            field: "birthDate",
            fieldType: "date",
            headerName: "Születési dátum",
            validation: {
              required: true,
            },
            valueGetter: (params) => {
              return params.row ? moment(params.row).format("YYYY-MM-DD") : "";
            },
            submitGetter: (value) => {
              return moment(value).format("YYYY-MM-DD");
            },
          },
          {
            field: "preferenceCard",
            fieldType: "boolean",
            fieldModel: "userHc",
            headerName: "Kedvezmény-kártya",
            valueGetter: (params) => {
              return params.row?.preferenceCard ? "igen" : "nem";
            },
            helperText:
              "Például: Start kártya, Start Plusz, Start Extra, Start Bónusz",
          },
          {
            field: "salaryRequestHourly",
            fieldType: "number",
            fieldModel: "userHc",
            headerName: "Órabér igény (nettó)",
            validation: {
              maxLength: 9,
            },
            valueGetter: (params) => {
              return params.row ? params.row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft" : "";
            },
          },
          {
            field: "salaryRequestMonthly",
            fieldType: "number",
            fieldModel: "userHc",
            headerName: "Havibér igény (nettó)",
            validation: {
              maxLength: 9,
            },
            valueGetter: (params) => {
              return params.row ? params.row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft" : "";
            },
          },
          {
            field: "picture",
            fieldType: "upload",
            headerName: "Profilkép",
          },
        ];
      }
      setColumns(sectionColumns);
    }
  }, [site, options, emailError]);

  useEffect(() => {
    if (formRefs.current[0]) {
      setTimeout(() => {
        formRefs.current[0].validate();
      }, 100);
    }
  }, [emailError]);

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    // onSubmit(values);
    return values;
  };

  return (
    <>
      {columns.length > 0 && (
        <Grid container spacing={0}>
          <Grid md={12}>
            <Forms
              tint={true}
              isDense={true}
              editable={isEditable}
              header={{ skip: true }}
              permissions={["create", "read", "update", "delete"]}
              columns={columns[0]}
              getObj={user}
              ref={(el) => (formRefs.current[0] = el)}
              submitFunc={(values) => handleSubmit(values)}
            ></Forms>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const Basic = forwardRef(BasicForwardRef);
Basic.propTypes = {
  user: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default Basic;
